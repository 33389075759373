.call-to-register {
  width: 100%;
}

.application-closed-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.square-checkbox {
  .ant-checkbox-inner {
    border-radius: 0;
  }
}

@media(min-width: 992px) {
  .application-closed-overlay {
    width: calc(100% - 48px);
    height: calc(100% - 48px);
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;