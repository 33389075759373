.team-filter {
  .teams-row {
    display: flex;
    margin-bottom: 16px;

    .team-indicator {
      cursor: pointer;
      height: 40px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #dedede;
      margin-left: 3px;
      margin-right: 3px;

      &.selected {
        border: 2px solid #0a7400;
      }

      &.in-progress {
        background-color: #fff488;
      }

      &.complete {
        background-color: rgba(110,255,1,0.27);
      }
    }
  }

  .team-table {
    tr:hover {
      background-color: #e8ffe5;
    }

    tr {
      cursor: pointer;
    }
  }
}

@primary-color: #0b8200;@border-radius-base: 10px;