.enter-scores {

  .entry-title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 16px;
  }

  .input-container {
    display: flex;
    justify-content: center;

    .input-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .sign-button {
    height: 50px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  .score {
    font-size: 25px;
    font-weight: bold;
    text-align: center;

    .score-sum {
      font-size: 45px;
      margin-top: -15px;
    }

    &.valid {
      color: #0a7400;
    }

    &.invalid {
      color: #ff4d4f;
    }

  }

  .custom-input {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    padding: 5px;
    height: 45px;
    width: 50px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;

    &.selected {
      border-color: #0a7400;
      border-width: 3px;
    }
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;