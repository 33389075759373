.empty-message {
  color: #000000;
  margin-bottom: 20px;
}

.add-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .add-button {
    margin-bottom: 10px;
    width: 100%;
  }
}

.entry-list {
  margin-top: 16px;

  .entry {
    font-size: 1.17em;
    font-weight: 500;

    display: flex;
    align-items: center;
    margin-bottom: 16px;
    text-align: left;
    justify-content: space-between;

    .anticon-close-circle {
      margin-left: 16px;
    }
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;