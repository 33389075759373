.numpad-container {

  display: flex;
  justify-content: center;

  .digit-row {
    display: flex;

    .digit {
      width: 80px;
      height: 60px;
      background-color: #0a7400;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin-right: 5px;
      margin-left: 5px;
      margin-bottom: 10px;
      border-radius: 10px;
      font-size: 30px;
      font-weight: bold;
    }
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;