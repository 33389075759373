tr.tie {
  background-color: rgba(214,41,0,0.24);
}

tr.placement-1 {
  background: rgba(255, 198, 0, 0.3);
}

tr.placement-2 {
  background-color: rgba(205, 205, 205, 0.3);
}

tr.placement-3 {
  background-color: rgba(167, 94, 0, 0.3);
}

.public-placement-tab {
  .club-name {
    color: #7f7f7f;
    font-size: 11px;
  }

  .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }
}

.public-placement-tab {
  .qualification {
    font-size: 11px;
  }

  .score-value {
    font-weight: 600;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 3px 8px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;