.social-buttons-container {
  border-radius: 10px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  height: 70px;
  background-color: #0a7400;
  align-items: center;
  padding: 16px;
  color: #ffffff;

  a {
    color: #ffffff;
  }

  .inner-container {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;