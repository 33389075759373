.ranking-item {

  border: 1px solid #dbdbdb;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 100px;

  .ranking-row {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 15px;

    .toggle-arrow {
      margin-right: 10px;
      cursor: pointer;
    }

    .ant-typography {
      margin-bottom: 0;
    }

    .rank-box {
      width: 80px;
      text-align: center;
    }

    .avatar {
      display: none;
    }

    .name {
      text-transform: uppercase;
      flex: 3;
    }

    .club {
      display: none;
    }

    .score {
      margin-left: 10px;
      margin-right: 10px;
      width: 75px;
      text-align: right;
      cursor: pointer;
    }

    @media(min-width: 600px) {
      .club {
        display: block;
        flex: 2;
      }
    }

    @media(min-width: 768px) {
      .avatar {
        display: block;
        width: 70px;
        margin-right: 20px;
      }
    }
  }

}

