.footer-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.qr-data-table {
  .error {
    background-color: red;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;