.item {

  .ant-image {
    float: left;
    margin-right: 15px;
  }

  img {
    cursor: pointer;
  }

  p {
    font-size: 16px;
    margin-left: 10px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;