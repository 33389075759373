.ranked-entries {
  width: 100%;

  .not-included {
    background-color: #cfcfcf;
  }

  .ranked-entry {
    display: flex;
    padding: 5px 30px 5px 30px;

    .date {
      display: none;
    }

    .name {
      flex: 1;
    }

    .placement {
      width: 55px;
      text-align: center;
    }

    .score {
      width: 40px;
      text-align: right;
    }

    .location {
      display: none;
    }

    @media(min-width: 500px) {
      .date {
        display: block;
        width: 90px;
      }
    }

    @media(min-width: 600px) {
      .location {
        display: inline;
      }
    }
  }

  @media(min-width: 800px) {
    .ranked-entry {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  @media(min-width: 1000px) {
    .ranked-entry {
      padding-left: 150px;
      padding-right: 150px;
    }
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;