.competitions {
  .title-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
  }

  .paging-type-select {
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .filter-row {
    display: flex;
    justify-content: space-between;

    .year-selector {
      margin-left: 10px;
    }
  }
}

.in-calendar-row {
  background-color: rgba(12, 255, 0, 0.05);
}

.week-paginator {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
}

@media(max-width: 400px) {
  .competitions-xs {
    .ant-pagination-prev, .ant-pagination-next {
      min-width: 40px;
    }
  }
}

td.type-col, td.action-col {
  padding: 0 !important;
}
@primary-color: #0b8200;@border-radius-base: 10px;