.competition-entries-list {
  margin-left: -8px;
  margin-right: -8px;

  &.historical {
    .entry {
      display: block;

      .entry-right {
        margin-top: 5px;
      }
    }
  }

  .ant-card-bordered {
    margin-bottom: 8px;
  }

  .entry {
    display: flex;
    justify-content: space-between;

    .entry-left {
      display: flex;

      .entry-index {
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #afafaf;
      }

      .entry-club {
        color: #afafaf;
        font-weight: normal;
      }
    }

    .entry-right {
      display: flex;
      align-items: center;
    }

    .entry-right.entry-editor {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 440px) {
      .entry-right.entry-editor {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    @media (max-width: 439px) {
      .entry-right.entry-editor .ant-tag {
        width: 70px;
        text-align: center;
        margin-bottom: 2px;
      }
    }
  }

  .entry.highlighted {
    animation-name: highlightedEntryColor;
    animation-duration: 2s;
    animation-iteration-count: 10;
  }

}

@keyframes highlightedEntryColor {
  0% { background-color: rgba(10, 200, 0, 0); }
  50% { background-color: rgba(10, 200, 0, 0.2); }
  100 { background-color: rgba(10, 200, 0, 0); }
}

@primary-color: #0b8200;@border-radius-base: 10px;