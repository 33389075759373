.qualification {
  tr.qualification-1 {
    td:nth-child(5) {
      background: rgba(255, 198, 0, 0.3);
    }
  }
  tr.qualification-2 {
    td:nth-child(5) {
      background-color: rgba(205, 205, 205, 0.3);
    }
  }
  tr.qualification-3 {
    td:nth-child(5) {
      background-color: rgba(167, 94, 0, 0.3);
    }
  }
}