.competition-card-bg {
  background: url(./club_bg.jpg);
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 200px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;