.competition-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .ant-tag {
    height: 22px;
  }

  .title-row {
    display: flex;
    margin-bottom: 8px;
  }

  .content-row {
    display: flex;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;