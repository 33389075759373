.score-tab {
    .ant-input-number-handler-wrap {
      visibility: hidden;
    }

  td.ant-table-column-sort {
    background: inherit;
  }

  .ant-table-tbody > tr.ant-table-row:hover:focus-within > td {
    background-color: #dcffdb;
  }

  tr:focus-within {
    background-color: #dcffdb;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;