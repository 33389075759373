.welcome-message {
  font-size: 51px;
  color: #ffffff;
  //background: linear-gradient(45deg, #0a7400, transparent);
  font-weight: bold;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 50px;
  margin-bottom: 24px;
}

.home-page {


  .ranking-table-row td {
    padding-right: 0;

    .ant-tag {
      margin: 0;
    }
  }
}

@media(max-width: 420px) {
  .home-page {
    .ranking-table-row td {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;