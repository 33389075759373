.custom-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  padding: 5px;
  height: 70px;
  margin-bottom: 20px;
  font-size: 50px;
  font-weight: bold;
}
@primary-color: #0b8200;@border-radius-base: 10px;