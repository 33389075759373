.competition-page {
  .ant-page-header-heading-title {
    white-space: normal;
    word-break: normal;
  }
}

.competition-xs {
  .ant-tabs-nav-list {
    .ant-tabs-tab:first-child {
      margin-left: 0 !important;
    }
  }
}

.description-container {
  img {
    width: 100%;
  }
}

.application-deadline {
  text-align: center;
}

.rating-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;

  .rating {
    width: 210px;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    flex: auto;
  }

  .stars {
    .ant-typography {
      margin-left: 15px;
      margin-top: 5px;
    }
  }
}

@primary-color: #0b8200;@border-radius-base: 10px;