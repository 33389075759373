.display {
  font-size: 40px;
  font-weight: bold;

  h3 {
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }


  .name-col {
    .club-name {
      font-size: 20px;
    }
  }

  &.score {

    font-size: 60px;
    margin-top: -50px;
    .category-row {
      font-size: 70px;
      text-align: center;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 999;
      background-color: white;
      padding-left: 20px;
      margin-left: -24px;
      margin-right: -24px;

      span {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    th {
      position: sticky;
      position: -webkit-sticky;
      top: 107px;
      z-index: 999;
    }
  }

  .ant-tag {
    font-size: 25px;
    line-height: 44px;
  }

  th {
    background-color: #0a7400;
    color: #ffffff;
  }

  td.centered {
    text-align: center;
  }

  td {
    padding: 18px;
  }

  .sum-row {
    font-weight: bold;
    font-size: 50px;
  }

  td.nowrap {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .teams-row {
    display: flex;
    margin-bottom: 16px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .team-indicator {
    font-size: 70px;
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #dedede;
    margin: 10px;

    &.selected {
      border: 2px solid #0a7400;
    }

    &.in-progress {
      background-color: #fff488;
    }

    &.complete {
      background-color: rgba(110,255,1,0.27);
    }
  }

  .processed {
    font-size: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .divided {
    width: 100%;
    height: 100px;
    background-color: #7c7c7c;
    clip-path: polygon(100% 0, 0 0, 0 100%);
    z-index: 1;
  }

  td.has-divided {
    padding: 0;
    position: relative;
  }

  .hit-value {
    position: absolute;
    top: -5px;
    left: 5px;
    z-index: 2;
    color: #ffffff;
  }

  .score-value {
    position: absolute;
    bottom: -7px;
    right: 5px;
    z-index: 2;
  }

  .ldts-hit-1 {
    background-color: rgba(0, 255, 21, 0.3);
  }

  .ldts-hit-0 {
    background-color: rgba(255,0,0,0.3);
  }

  .ldts-placement-1 {
    background: rgba(255, 198, 0, 1);
  }

  .ldts-placement-2 {
    background-color: rgba(205, 205, 205, 1);
  }

  .ldts-placement-3 {
    background-color: rgba(167, 94, 0, 1);
  }

  .ldts-col {
    text-align: center;
    font-size: 50px;
  }

  .ldts-name-col {
    font-size: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 800px;
  }

  &.ldts {
    h3 {
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .ldts-table {
    tr:nth-child(2n) {
      background-color: rgba(0, 255, 21, 0.06);
    }
  }

  .threed-placement-1 {
    background: rgba(255, 198, 0, 1);
  }

  .threed-placement-2 {
    background-color: rgba(205, 205, 205, 1);
  }

  .threed-placement-3 {
    background-color: rgba(167, 94, 0, 1);
  }

  .threed-col {
    text-align: center;
  }

  .tie-col {
    background-color: rgb(187, 0, 0);
    color: white;
  }

}
@primary-color: #0b8200;@border-radius-base: 10px;