.misz-banner {

  border: 2px solid #d90000;

  .misz-banner-content {
    display: flex;
  }

  img {
    width: 60px;
    float: left;
    margin-right: 20px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;