.training {
  user-select: none;

  .training-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon {
      color: #d62900;
    }
  }
  
  .training-description {
    display: flex;
    justify-content: space-between;
  }

  .selected-row {
    background-color: rgba(12, 255, 0, 0.05);
  }

  .create-button {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #0a7400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.4);
  }

  .scoring-buttons-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .score-button {
      border: 1px solid #0a7400;
      border-radius: 10px;
      width: 55px;
      height: 55px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      background-color: #0a7400;
      color: #ffffff;
    }
  }

  .target-score-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 24px;

    .score-value {
      width: 50px;
      height: 65px;
      text-align: center;
      font-size: 40px;
      border-bottom: 3px solid transparent;
      margin-left: 10px;
      margin-right: 10px;

      &.empty {
        color: #e3e3e3;
      }

      &.active {
        border-bottom-color: #0a7400;
      }
    }
  }

  .target-selector-container {
    display: flex;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 24px;

    .paging {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .athlete-selector-container {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 24px;

    .name {
      display: flex;
      justify-content: center;
    }

    .tags {
      display: flex;
      justify-content: center;
      margin-top: 8px;
    }

    .paging {
      margin-left: 10px;
      margin-right: 10px;
      width: 24px;
      display: flex;
      align-items: center;
    }
  }

  .statistics {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  .paging-marks-container {
    display: flex;
    justify-content: center;
    padding: 5px;

    .paging-mark {
      height: 8px;
      flex: 1 1 0px;
      border: 1px solid black;
      margin: 2px;
      max-width: 30px;

      &.complete {
        background-color: #0a7400;
      }

      &.active {
        background-color: #52c900;
      }
    }
  }
}

@primary-color: #0b8200;@border-radius-base: 10px;