@import '~antd/dist/antd.less';

.worker-update-message {
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

.offline-message {
  width: 100%;
  height: 20px;
  background-color: #d62900;
  justify-content: center;
  display: flex;
  color: #ffffff;
}

.site-layout-content {
  min-height: calc(100vh - 158px)
}

.ant-card-bordered {
  //box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
}

.ant-layout-content {
  padding-top: 24px !important;
}

@media (max-width: 991px) {
  .ant-layout-content {
    padding: 24px 8px !important;
  }

  .site-layout-content {
    min-height: calc(100vh - 134px);
  }

  .ant-layout-header {
    padding-left: 24px;
  }
}

.ant-page-header {
  padding-top: 0;
}


@primary-color: #0b8200;@border-radius-base: 10px;