.table-even-row-background-color {
  background-color: rgba(65, 193, 61, 0.2);
}

tr.highlighted {
  animation-name: highlightedTrColor;
  animation-duration: 2s;
  animation-iteration-count: 10;
}

@keyframes highlightedTrColor {
  0% { background-color: rgba(10, 200, 0, 0); }
  50% { background-color: rgba(10, 200, 0, 0.2); }
  100 { background-color: rgba(10, 200, 0, 0); }
}
@primary-color: #0b8200;@border-radius-base: 10px;