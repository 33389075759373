.targets {
  position: relative;



  .stake-switch.ant-switch {
    background-color: #002bd6;
  }

  .stake-switch.ant-switch-checked {
    background-color: #d62900;
  }

  .stats-background {
    position: absolute;
    top: 112px;
    width: calc(100% - 48px);

    .stats-row {
      height: 30px;

      .blue-stake-row {
        height: 30px;
        background-color: rgba(0,17,193,0.1);
      }

      .red-stake-row {
        height: 30px;
        background-color: rgba(193, 0, 0, 0.1);
      }

    }
  }

  .targets-table {
    width: 100%;

    .table-row {
      height: 30px;
      vertical-align: middle;
      border: 1px solid rgba(0, 0, 0, 0.25);
    }

    td.centered {
      text-align: center;
    }
  }
}

@primary-color: #0b8200;@border-radius-base: 10px;