.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #0a7400;
}

.ant-menu-submenu-active {
  color: #ffffff !important;
}

.ant-menu-dark .ant-menu-item > span > a {
  color: #ffffff !important;
}

.ant-menu.ant-menu-dark {
  color: #ffffff !important;;
}

.ant-menu-dark .ant-menu-item {
  color: #ffffff !important;
}

.ant-layout-header {
  background: #0a7400;

  a {
    color: #ffffff !important;
  }
}

.ant-drawer-header {
  border-bottom: 0;
  background-color: #0a7400;
  border-radius: 0;
}

.ant-drawer-title {
  color: #ffffff !important;;
}

.ant-drawer-body {
  background-color: #0a7400;
  display: flex;
  flex-direction: column;
}

ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-horizontal {
  background: transparent;
  justify-content: flex-end;
}

.logo {
  width: 180px;
  float: left;
  color: #ffffff;
  font-weight: bold;
  display: flex;
}

.logo img {
  width: 50px;
  margin-top: 5px;
  margin-right: 5px;
  height: 50px;
}

.logo a {
  display: inline-block;
  font-size: 24px;
  text-transform: capitalize;
  color: #ffffff;
}

.menuCon {
  width: calc(100% - 180px);
  float: left;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.ant-menu-submenu {
  .ant-divider-horizontal {
    margin: 0;
  }
}

.menuCon .leftMenu {
  float: left;
  min-width: 620px;
}

.menuCon .rightMenu {
  float: right;
  min-width: 311px;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 17px;
  display: none;
  background: none;
  color: #ffffff;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #ffffff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #ffffff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

.ant-menu.ant-menu-dark {
  background: #0a7400;
}

.profile-mobile {
  display: none;
}

.logout-desktop, .profile-desktop {
  .anticon {
    margin-right: 0;
  }
}

.logout-mobile {
  display: none;
}

@media (max-width: 1220px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 0 20px;
  }

  .profile-desktop {
    display: none;
  }

  .profile-mobile {
    display: block;
  }

  .logout-desktop {
    display: none;
  }

  .logout-mobile {
    display: block;
  }

}



.login-button {
  padding-left: 0px;
}

.play-store-button {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
@primary-color: #0b8200;@border-radius-base: 10px;