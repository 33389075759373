.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #0a7400;
  color: #ffffff;
  padding: 20px;
  text-align: center;

  .consent-text {
    margin-right: 7px;
  }

  button {
    margin-left: 20px;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
  }

  .dl-badge {
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;