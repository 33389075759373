.teams-page {

  .ant-pagination {
    margin-left: 25px;
  }

  .search-input {
    margin-left: 25px;
    display: flex;
  }

  .ant-card-body {
    padding-left: 0;
  }

  .group-border {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .lock-button {
    text-align: center;
    width: 24px;
    visibility: hidden;
    cursor: pointer;
  }

  .group-body {
    width: 100%;
    padding: 2px;
  }

  .group-border.grouped {
    .group-body{
      border: 1px dashed #0b8200;
    }

    .lock-button {
      visibility: visible;
    }
  }

  .entry {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e3e3e3;
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 3px 0 3px 8px;
    background-color: #FFFFFF;

    .entry-left {
      display: flex;

      .entry-index {
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #afafaf;
      }

      .entry-club {
        color: #afafaf;
        font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 150px;
      }
    }

    .entry-right {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
    }

    .category-info {
      display: flex;
      align-items: center;
    }

    .category-info.entry-editor {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .tournament-info {
      padding-right: 10px;
    }

    @media (min-width: 440px) {
      .category-info.entry-editor {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    @media (max-width: 439px) {
      .category-info.entry-editor .ant-tag {
        width: 70px;
        text-align: center;
        margin-bottom: 2px;
      }
    }
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;