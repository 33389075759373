.fee-summary-modal {
  border: 1px solid #dfdfdf;
  width: 300px;
  position: fixed;
  top: 16px;
  right: 20px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 7px 7px 20px 0 rgb(0 0 0 / 8%);
  border-radius: 10px;


  .header-row {
    background-color: #0a7400;
    color: #ffffff;
  }
  .sum-row {
    font-weight: bold;
  }

  .table-container {
    max-height: 400px;
    overflow-y: auto;
  }

  table {
    margin-bottom: 10px;

    td {
      padding: 5px;
    }
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;