.kinetic {
  max-width: 400px;

  input {
    width: 100px;
    height: 50px;
    margin-right: 10px;
    font-size: 24px;
  }

  .value {
    margin-bottom: 20px;
  }

  .result-value {
    font-size: 50px;

    &.pass {
      color: #0a7400;
    }

    &.fail {
      color:#d62900;
    }
  }

  .result-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;