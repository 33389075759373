.results {
  width: 100%;
}

tr.tie {
  background-color: rgba(214,41,0,0.24);
}

tr.no-score {
  background-color: rgba(204, 204, 204, 0.24);
  opacity: 0.3;
}

.placement-column {
  font-weight: bold;
}

th.ant-table-cell.round-column:nth-child(even) {
 background-color: rgb(255, 255, 255);
}

tr.placement-1 {
  background: rgba(255, 198, 0, 0.3);
}

tr.placement-2 {
  background-color: rgba(205, 205, 205, 0.3);
}

tr.placement-3 {
  background-color: rgba(167, 94, 0, 0.3);
}

.placement-tab {
  display: flex;

  .categories {
    flex: 0 0 350px;


    .category {
      cursor: pointer;
      margin-right: 16px;
    }

    .category:hover {
      color: #0a7400;
    }

    .category-list {
      display: flex;
    }
  }

  .ant-back-top {
    right: 30px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;