.results-banner {

  border: 2px solid #0a7400 !important;
  background-color: #0b8200 !important;
  color: #ffffff !important;

  h3, h4 {
    color: #ffffff;
  }

  .results-logo {
    font-size: 40px;
    margin-right: 20px;
  }

  .results-banner-content {
    display: flex;
  }
}

@primary-color: #0b8200;@border-radius-base: 10px;