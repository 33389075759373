.name-box {
  display: flex;

  .avatar {
    margin-right: 20px;
  }
}

.rank-box {
  width: 50px;

  .rank {
    .ant-typography{
      margin-bottom: 0.62em;
      line-height: 1.1em;
    }
  }
}

.trophies {

  display: flex;
  justify-content: space-between;

  .trophy {
    font-size: 30px;
    text-align: center;
  }

  .trophy-icon {
    font-size: 60px;
  }
}

.score-average-col {
}

.achievement {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.08);
  margin-left: -50px;
  margin-top: 20px;

  .title {
    font-weight: 600;
  }

  .trophy {
    margin-right: 10px;
    font-size: 20px;
  }
}
@primary-color: #0b8200;@border-radius-base: 10px;